import "core-js/modules/es6.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import OrderSummary from '@modules/cash/components/deposit/OrderSummary';
export default {
  name: 'MethodsSaved',
  components: {
    OrderSummary: OrderSummary
  },
  props: {
    savedProviders: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    paymentId: {
      type: Number,
      default: null
    }
  },
  data: function data() {
    return {
      activeCardIndex: null,
      isShowIcon: true
    };
  },
  computed: {
    providerTrustly: function providerTrustly() {
      return this.$store.getters['cash/providerTrustly'];
    },
    isDisabledBtn: function isDisabledBtn() {
      return this.providerTrustly;
    },
    isActiveCard: function isActiveCard() {
      return this.$store.getters['cash/isActiveCard'];
    }
  },
  methods: {
    backProviders: function backProviders() {
      this.$emit('backProviders');
    },
    selectCard: function selectCard(provider, index) {
      this.activeCardIndex = null;
      this.$store.dispatch('cash/setProviderTrustly', provider);
      this.$store.dispatch('cash/setIsActiveCard', index);
    },
    slideCard: function slideCard(index) {
      this.activeCardIndex = index;
    },
    selectSavedProviders: function selectSavedProviders(_ref) {
      var paymentId = _ref.paymentId,
          tokenId = _ref.tokenId;
      window.$store.gaCash.clickButton({
        location: 'select_payments_type',
        buttonName: 'pay'
      });
      this.$emit('selectSavedProviders', {
        paymentId: paymentId,
        tokenId: tokenId
      });
    },
    removeSavedProviders: function removeSavedProviders(_ref2) {
      var tokenId = _ref2.tokenId;
      this.$emit('removeSavedProviders', {
        tokenId: tokenId
      });
    },
    showWidget: function showWidget() {
      window.$store.gaCash.clickButton({
        location: 'select_payments_type',
        buttonName: 'add_new_bank'
      });
      this.$emit('showWidget');
    }
  },
  destroyed: function destroyed() {
    this.$store.dispatch('cash/setProviderTrustly', null);
    this.$store.dispatch('cash/setIsActiveCard', null);
  }
};