//
//
//
//
//
//
//
//
//
//
//
// import LoadingTsevoLoader from '@modules/cash/components/deposit/LoadingTsevo/LoadingTsevoLoader';
export default {
  name: 'LoadingTsevo' // components: {
  //   LoadingTsevoLoader,
  // },

};