import "core-js/modules/es6.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'FeedbackArea',
  props: {
    field: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    fieldName: {
      type: String,
      default: ''
    },
    maxlength: {
      type: Number,
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    error: {
      type: String,
      default: null
    },
    rows: {
      type: Number,
      default: null
    },
    cols: {
      type: Number,
      default: null
    },
    isError: {
      type: Boolean,
      default: false
    },
    value: [String, Number]
  },
  data: function data() {
    return {
      errorMessage: null,
      changed: false,
      errorState: false
    };
  },
  watch: {
    isError: function isError() {
      this.errorState = this.isError;
    }
  },
  computed: {
    inputValue: {
      get: function get() {
        return this.value;
      },
      set: function set(val) {
        this.$emit('input', val);
      }
    }
  },
  methods: {
    resetFieldError: function resetFieldError() {
      this.errorState = false;
      this.$emit('resetError');
    },
    input: function input() {
      if (!this.changed) {
        window.$store.form.change({
          formName: 'deposit',
          formField: this.fieldName
        });
        this.changed = true;
      }

      this.resetFieldError();
    }
  }
};