import "core-js/modules/es6.array.map.js";
import "core-js/modules/es6.regexp.split.js";
import "core-js/modules/es6.string.includes.js";
import "core-js/modules/es7.array.includes.js";
//
//
//
//
//
//
export default {
  name: 'MethodsBadge',
  props: {
    method: {
      type: Object,
      default: function _default() {}
    }
  },
  computed: {
    isBonus: function isBonus() {
      var _this$method;

      return (_this$method = this.method) === null || _this$method === void 0 ? void 0 : _this$method.extraCash;
    },
    isPrefered: function isPrefered() {
      var _this$method2;

      return (_this$method2 = this.method) === null || _this$method2 === void 0 ? void 0 : _this$method2.recommended;
    },
    currencySymbol: function currencySymbol() {
      var _this$method3, _this$method3$currenc;

      return (_this$method3 = this.method) === null || _this$method3 === void 0 ? void 0 : (_this$method3$currenc = _this$method3.currencies[0]) === null || _this$method3$currenc === void 0 ? void 0 : _this$method3$currenc.currencySymbol;
    },
    bonus: function bonus() {
      var _this$method4;

      var bonusPackege = (_this$method4 = this.method) === null || _this$method4 === void 0 ? void 0 : _this$method4.extraCash.split(',').map(function (item) {
        var bonus = item.trim();
        if (!item.includes('%')) bonus = "+ ".concat(item, " ENTRIES");else bonus = "+".concat(item, " EXTRA");
        return bonus;
      });
      return bonusPackege.join(' ');
    },
    text: function text() {
      return this.isBonus ? this.bonus : 'PREFERRED';
    }
  }
};