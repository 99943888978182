import "core-js/modules/es6.number.constructor.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import NumberFormat from '@modules/cash/mixins/NumberFormat';
export default {
  name: 'OrderSummary',
  mixins: [NumberFormat],
  props: {
    availableToSelectPayMethod: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    orderSummary: function orderSummary() {
      return this.$store.getters['cash/orderSummary'];
    },
    bonus: function bonus() {
      var _this$$store$getters$;

      return (_this$$store$getters$ = this.$store.getters['cash/orderSummary']) === null || _this$$store$getters$ === void 0 ? void 0 : _this$$store$getters$.bonus;
    },
    currencySymbol: function currencySymbol() {
      return window.config.appMain.currencySymbol;
    },
    isPromo: function isPromo() {
      var _this$orderSummary;

      return (_this$orderSummary = this.orderSummary) === null || _this$orderSummary === void 0 ? void 0 : _this$orderSummary.promoOfferPreset;
    },
    isShowLabel: function isShowLabel() {
      var _this$orderSummary2, _this$orderSummary2$p, _this$orderSummary3, _this$orderSummary3$p;

      return ((_this$orderSummary2 = this.orderSummary) === null || _this$orderSummary2 === void 0 ? void 0 : (_this$orderSummary2$p = _this$orderSummary2.promoOfferPreset) === null || _this$orderSummary2$p === void 0 ? void 0 : _this$orderSummary2$p.bestDeal) || ((_this$orderSummary3 = this.orderSummary) === null || _this$orderSummary3 === void 0 ? void 0 : (_this$orderSummary3$p = _this$orderSummary3.promoOfferPreset) === null || _this$orderSummary3$p === void 0 ? void 0 : _this$orderSummary3$p.mostPopular);
    },
    showBadge: function showBadge() {
      var _this$orderSummary4, _this$orderSummary4$p, _this$orderSummary5, _this$orderSummary5$p;

      return ((_this$orderSummary4 = this.orderSummary) === null || _this$orderSummary4 === void 0 ? void 0 : (_this$orderSummary4$p = _this$orderSummary4.promoOfferPreset) === null || _this$orderSummary4$p === void 0 ? void 0 : _this$orderSummary4$p.badgeLabel) && ((_this$orderSummary5 = this.orderSummary) === null || _this$orderSummary5 === void 0 ? void 0 : (_this$orderSummary5$p = _this$orderSummary5.promoOfferPreset) === null || _this$orderSummary5$p === void 0 ? void 0 : _this$orderSummary5$p.imageBadge);
    },
    badgeLabel: function badgeLabel() {
      var _this$orderSummary6, _this$orderSummary6$p, _this$orderSummary7, _this$orderSummary7$p;

      return (_this$orderSummary6 = this.orderSummary) !== null && _this$orderSummary6 !== void 0 && (_this$orderSummary6$p = _this$orderSummary6.promoOfferPreset) !== null && _this$orderSummary6$p !== void 0 && _this$orderSummary6$p.badgeLabel ? JSON.parse((_this$orderSummary7 = this.orderSummary) === null || _this$orderSummary7 === void 0 ? void 0 : (_this$orderSummary7$p = _this$orderSummary7.promoOfferPreset) === null || _this$orderSummary7$p === void 0 ? void 0 : _this$orderSummary7$p.badgeLabel) : '';
    },
    badgePercent: function badgePercent() {
      var _this$badgeLabel;

      return (_this$badgeLabel = this.badgeLabel) === null || _this$badgeLabel === void 0 ? void 0 : _this$badgeLabel.percent;
    },
    badgeText: function badgeText() {
      var _this$badgeLabel2;

      return (_this$badgeLabel2 = this.badgeLabel) === null || _this$badgeLabel2 === void 0 ? void 0 : _this$badgeLabel2.text;
    },
    usualPrice: function usualPrice() {
      var _this$orderSummary8, _this$orderSummary8$p, _this$orderSummary9, _this$orderSummary9$p;

      return (_this$orderSummary8 = this.orderSummary) !== null && _this$orderSummary8 !== void 0 && (_this$orderSummary8$p = _this$orderSummary8.promoOfferPreset) !== null && _this$orderSummary8$p !== void 0 && _this$orderSummary8$p.usualPrice ? Number((_this$orderSummary9 = this.orderSummary) === null || _this$orderSummary9 === void 0 ? void 0 : (_this$orderSummary9$p = _this$orderSummary9.promoOfferPreset) === null || _this$orderSummary9$p === void 0 ? void 0 : _this$orderSummary9$p.usualPrice).toFixed(2) : null;
    }
  },
  methods: {
    chooseCardPayMethod: function chooseCardPayMethod() {
      if (this.availableToSelectPayMethod) {
        this.$emit('chooseCardPayMethod');
        window.$store.gaCash.clickButton({
          location: 'view_payments_window',
          buttonName: 'payment_package'
        });
      }
    }
  }
};