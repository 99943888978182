//
//
//
//
//
//
export default {
  name: 'Accordion',
  provide: function provide() {
    return {
      toggle: this.toggle
    };
  },
  props: {
    autoCollapse: {
      type: Boolean,
      default: true
    }
  },
  data: function data() {
    return {
      isCloseAll: false
    };
  },
  methods: {
    toggle: function toggle(event) {
      var _this = this;

      this.$children.forEach(function (item) {
        if (item.$el.id === event.currentTarget.parentElement.id) {
          item.active = !item.active;
        } else if (_this.autoCollapse) {
          item.active = false;
        }
      });
    },
    collapseAll: function collapseAll(_ref) {
      var isOpen = _ref.isOpen;
      this.$children.forEach(function (item) {
        item.active = isOpen;
      });
    }
  }
};