import "core-js/modules/es6.number.constructor.js";
import "core-js/modules/es6.array.filter.js";
import "core-js/modules/es6.array.find-index.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import NumberFormat from '@modules/cash/mixins/NumberFormat';
import PromEntriesPopup from '@modules/cash/components/modals/PromEntriesPopup';
export default {
  name: 'Payments',
  mixins: [NumberFormat],
  props: {
    payments: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    currency: {
      type: String,
      default: ''
    }
  },
  components: {
    PromEntriesPopup: PromEntriesPopup
  },
  data: function data() {
    return {
      isShowEntriesPopup: false,
      activePromotionId: null
    };
  },
  computed: {
    // showBadge() {
    //   return this.bonusPromo?.promoOfferPreset?.badgeLabel
    //     && this.bonusPromo?.promoOfferPreset?.imageBadge;
    // },
    // badgeLabel() {
    //   return this.bonusPromo?.promoOfferPreset?.badgeLabel ? JSON.parse(this.bonusPromo?.promoOfferPreset?.badgeLabel) : '';
    // },
    // badgePercent() {
    //   return this.badgeLabel?.percent;
    // },
    // badgeText() {
    //   return this.badgeLabel?.text;
    // },
    usualPrice: function usualPrice() {
      var _this$bonusPromo, _this$bonusPromo$prom, _this$bonusPromo2, _this$bonusPromo2$pro;

      return (_this$bonusPromo = this.bonusPromo) !== null && _this$bonusPromo !== void 0 && (_this$bonusPromo$prom = _this$bonusPromo.promoOfferPreset) !== null && _this$bonusPromo$prom !== void 0 && _this$bonusPromo$prom.usualPrice ? Number((_this$bonusPromo2 = this.bonusPromo) === null || _this$bonusPromo2 === void 0 ? void 0 : (_this$bonusPromo2$pro = _this$bonusPromo2.promoOfferPreset) === null || _this$bonusPromo2$pro === void 0 ? void 0 : _this$bonusPromo2$pro.usualPrice).toFixed(2) : null;
    },
    isShowMenu: function isShowMenu() {
      return this.$store.getters['cash/menuItems'];
    },
    bonusPromo: function bonusPromo() {
      return this.payments.filter(function (payment) {
        var _payment$promoOfferPr;

        return ((_payment$promoOfferPr = payment.promoOfferPreset) === null || _payment$promoOfferPr === void 0 ? void 0 : _payment$promoOfferPr.bestDeal) === true;
      })[0];
    },
    getPayments: function getPayments() {
      return this.payments; // return this.payments.filter((payment) => payment !== this.bonusPromo);
    },
    depositsCount: function depositsCount() {
      return this.$store.getters['cash/profile'].depositsCount;
    },
    profile: function profile() {
      return this.$store.getters['cash/profile'];
    }
  },
  methods: {
    badgeLabel: function badgeLabel(promotion) {
      var _promotion$promoOffer, _promotion$promoOffer2;

      return promotion !== null && promotion !== void 0 && (_promotion$promoOffer = promotion.promoOfferPreset) !== null && _promotion$promoOffer !== void 0 && _promotion$promoOffer.badgeLabel ? JSON.parse(promotion === null || promotion === void 0 ? void 0 : (_promotion$promoOffer2 = promotion.promoOfferPreset) === null || _promotion$promoOffer2 === void 0 ? void 0 : _promotion$promoOffer2.badgeLabel) : '';
    },
    buyCoins: function buyCoins(promotion, offerType) {
      var _promotion$promoOffer3;

      var offerId = promotion.promoOfferPreset ? (_promotion$promoOffer3 = promotion.promoOfferPreset) === null || _promotion$promoOffer3 === void 0 ? void 0 : _promotion$promoOffer3.id : promotion.preset.id;

      if (promotion.status !== 'active') {
        this.activePromotionId = promotion.preset.id;
        return;
      }

      var index = this.payments.findIndex(function (p) {
        return p.preset.id === promotion.preset.id;
      });
      this.$store.dispatch('cash/setLastPresetId', promotion.preset.id);
      this.$store.dispatch('cash/setOfferType', offerType);
      this.$store.dispatch('cash/setOfferId', offerId);
      this.$store.dispatch('cash/setOrderSummary', promotion);
      this.$emit('buyCoins', index);
    },
    toVerify: function toVerify() {
      window.dispatchEvent(new CustomEvent('OPEN_VERIFY'));
      this.closeCash();
    },
    closeCash: function closeCash() {
      this.$router.push('/');
    }
  }
};