//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import Accordion from '@modules/cash/components/accordion/Accordion';
import AccordionItem from '@modules/cash/components/accordion/AccordionItem';
export default {
  name: 'HelpPopup',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    isActiveApplePay: {
      type: Boolean,
      default: false
    },
    applePayUrl: {
      type: String,
      default: ''
    },
    methods: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  components: {
    Accordion: Accordion,
    AccordionItem: AccordionItem
  },
  methods: {
    openChat: function openChat() {
      window.$store.$support.open();
    },
    selectMethod: function selectMethod(_ref) {
      var methodId = _ref.methodId,
          currencyId = _ref.currencyId;
      this.$emit('selectMethod', {
        methodId: methodId,
        currencyId: currencyId
      });
    }
  },
  created: function created() {
    var _this = this;

    window.addEventListener('SELECTED_METHOD_SUCCESS', function (event) {
      _this.$refs.applePayIframe[0].contentWindow.postMessage({
        eventId: 'SELECTED_METHOD_SUCCESS',
        paymentId: event.detail.paymentId
      }, '*');
    });
  }
};