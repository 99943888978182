//
//
//
//
//
//
//
//
//
//
export default {
  name: 'BackProviders',
  methods: {
    backProviders: function backProviders() {
      this.$emit('backProviders');
    }
  }
};