//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
export default {
  name: 'AccordionItem',
  inject: ['toggle'],
  props: {
    initActive: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      active: this.initActive
    };
  },
  methods: {
    startTransition: function startTransition(el) {
      el.style.overflow = 'hidden';
      el.style.height = "".concat(el.scrollHeight, "px");
    },
    endTransition: function endTransition(el) {
      el.style.overflow = 'visible';
      el.style.height = '';
    }
  }
};